@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import '../styles/color';

*, html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  font-family: "Work Sans", sans-serif;
  background-color: $main-bg;
  line-height: 1.5;
  min-height: 100vh;
}

body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin-block-end: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

h1, h2,
h3, h4 {
  text-wrap: balance;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
  text-decoration: none;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input, button,
textarea, select {
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

input[type='button']:hover{
  background-color: $common-color;
}

hr {
  color: $common-color;
}

// Utility
.buttonStyle {
  padding: 10px 30px;
  border: 1px solid $common-color;
  outline: none;
  background-color: $main-bg;
}

@media screen and (max-width : 440px) {
  .buttonStyle {
    padding: 8px;
    font-size: 12px;
  }
}