@import '../../styles/color';

.nav-section{
    min-height: 18vh;

    .sub_head{
        margin-left: 80px;
    }

    nav {
        width: 100%;
        background-color: $common-color;    
        
        .menus {
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            &.row-dir {
                flex-direction: row;
            }
    
            li {
                padding: 5px 10px;
                cursor: pointer;
            }
        }
    }
}

.subChild{
    background-color: $white;
    border-radius: 0;
    color: $text-color;
    padding: 5px;
}

.video_div{
    height: 82vh;
    width: 100%;
    overflow: hidden; 
    position: relative;

    .content{
        position: absolute;
        left: 10%;
        z-index: 99;
        top: 35%;
        color: $main-bg;
        overflow: hidden;

        h1, h2{
            font-weight: 600;
            font-size: 3rem;
            margin: 0;
        }

        h3{
            padding-top: 12px;
            margin: 0;
        }
    }
    
    video, img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover; 
    }
}

.overlay{
    position: absolute;
    left: 0;
    right: 0;
    height: inherit;
    background-color: $overlay;
}

.fa-bars{
    cursor: pointer;
}

.fa-arrow-up-right-from-square{
    font-size: 10px;
    color: $common-color;
}

.instagram{
    .fa-instagram {
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
    }
}

.fa-whatsapp{
    color:#fff;
    background:
     linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
     radial-gradient(#25d366 60%,transparent 0);
     font-size: 20px;
}

.fa-facebook{
  color: #3b5998;
  background-image: linear-gradient( to bottom, transparent 20%, white 20%, white 93%, transparent 93% );
  background-size: 55%;
  background-position: 70% 0;
  background-repeat: no-repeat;
  font-size: 20px;
}

.mobile {
    position: absolute;
    right: -100%; 
    top: 0;
    bottom: 0;
    width: 300px;
    z-index: 99;
    opacity: 0;
    transform: translateX(100%);
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: $text-color;
    border: 1px solid $border-color;
    padding: 15px;
    color: $main-bg;
    transition: transform .7s ease-in, opacity .7s ease-in, right .7s ease-in;
}

.mobile.open {
    right: 0;
    opacity: 1;
    transform: translateX(0);
}

.fa-gears{
    color: $common-color;
}


.pdf-view{
    padding: 10px;
    display: flex;
    align-self: center;
    justify-content: center;
    
    canvas.react-pdf__Page__canvas {
        margin-bottom: 50px;
    }

    .react-pdf__Page {
        margin-bottom: 10px;
    }
}

@media screen and (max-width : 440px) {
    .nav-section{
        min-height: 10vh;
        .left{
            img{
                width: 45px;
            }

            .site_name {
                p{
                    font-size: 0.9rem;
                }

                .sub_head{
                    margin-left: 70px;
                }
            }
        }
    }
    .video_div{
        height: 90vh;
    }

    .pdf-view{
        canvas.react-pdf__Page__canvas {
            width: 360px !important;
        }
    }
}

@media screen and (max-width : 996px) {
    nav {
        ul {
            font-size: 0.6rem;
        }
        .subChild{
            font-size: 0.6rem;
        }
    }

    .video_div{
        h2{
            top: 40%;
        }
    }
}

@media screen and (max-width : 770px) {
    .nav-section{
        min-height: 10vh;
    }
    nav {
        ul {
            font-size: 0.6rem;
        }

        .subChild{
            font-size: 0.6rem;
        }
    }

    .video_div{
        height: 90vh;
        
        h2{
            top: 45%;
            font-size: 2rem !important;
        }
    }
}